import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { GROWERS_PRINTERS } from "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "Printers",
      "search-by": "Name,Description",
      "ph-search-by": "Search by keywords"
    }
  }, [_c("text-field", {
    key: "Name",
    attrs: {
      "data-index": "name",
      "title": "Printer Name"
    }
  }), _c("text-field", {
    key: "Description",
    attrs: {
      "data-index": "description",
      "title": "Printer  Description"
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListPrinters"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListPrinters = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-printers"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.printers",
      "api-url": _vm.apiUrl,
      "create-route": "/watties-grower/printers/create",
      "edit-route": "/watties-grower/printers/:id",
      "list": _vm.ListPrinters,
      "page": _vm.page
    }
  }), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "Printers",
  data() {
    return {
      ListPrinters,
      apiUrl,
      page: GROWERS_PRINTERS,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "printers",
          title: "Printers",
          path: "/watties-grower/printers"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
